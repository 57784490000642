import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import RCEPImg from 'images/blog-images/rcep.png';
import RCEPImgFeatured from 'images/featured-image/rcep-featured.png';
import CrossBorderImg from 'images/blog-images/cross-border-logistics.png';
import ShipkooImg from 'images/blog-images/ship-globally.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query DevelopmentPatternBG {
      file(relativePath: { eq: "rcep.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="regional comprehensive economic partnership"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="The Development Pattern of Cross-Border Industries in 2021 | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-the-development-pattern" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "The Development Pattern of Cross-Border Industries in 2021",
              content: intl.formatMessage({ id: "blog-post-meta-title-the-development-pattern" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: RCEPImgFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="the-development-pattern-of-cross-border-industries-in-2021"
        title="The Development Pattern of Cross-Border Industries in 2021"
        date="2021-01-14"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              Looking back at 2020, the economic world has undergone major changes. COVID-19 epidemic, Brexit, and the change of the US president etc, these big changes will all affect the development pattern of cross-border industries. Now that 2021 has started, what are the opportunities for cross-border eCommerce sellers this year?
            </p>

            <h5>
              <strong>
                Asia-Pacific nations signed the Regional Comprehensive Economic Partnership (RCEP)
              </strong>
            </h5>

            <p>
              The speed of signing the RCEP agreement is enough to prove how severe the economic impact of the epidemic is on ASEAN, Japan, South Korea, Australia and New Zealand and other countries. Now they urgently need the Chinese market to help stabilize their own economy and inject future economic development. The successful signing of RCEP also represents the formal formation of a tripartite world trade pattern. China and ASEAN have become each other's largest trading partners. It is expected that the world economic center of gravity will continue to move to Asia in the future. This global crisis has further highlighted the competitive advantages of cross-border eCommerce, which is enough to predict that there will be increasing and continuing business opportunities for eCommerce sellers this year.
            </p>

            <Image src={RCEPImg} className="" alt="regional comprehensive economic partnership"/>

            <p className="text-small-center">
              (Photo from China chinadaily.com.cn)
            </p>

            <h5>
              <strong>
                Evolution of cross-border logistics
              </strong>
            </h5>

            <p>
              With the rapid development of cross-border eCommerce, inbound parcels from Europe and the United States have also shown exponential growth. In 2020, my country’s postal parcels have increased their tariffs several times. The era profits of a few dollars will gradually disappear. The increase in express shipping and dropshipping costs is the general trend. Therefore, it is important for eCommerce sellers to get fully prepared for price increases.
            </p>

            <p>
              The continuous price increase of small packages will bring about the following changes in the cross-border logistics situation:
            </p>

            <UnorderedList>
              <li>
                Special line small package <br />
                Relying on commercial customs clearance, using the destination country's logistics network, tariffs are not affected by the UPU, shipments will increase significantly, and the corresponding transportation capacity and customs clearance resources are more popular.
              </li>
              <li>
                Local fulfillment <br />
                The proportion of shipments continues to rise, and more small and medium-sized sellers will consider deploying using local fulfillment. The proportion of using local fulfillment may exceed the use of origins fulfillment. Therefore, cost-effective third-party overseas warehouse resources will become more popular.
              </li>
              <li>
                Traditional postal services <br />
                The dominance of a single company will change. The domestic giants such as SF Express will explore the global market. As more export companies and brand merchants enter the market, cross-border logistics will compete with each other.
              </li>
            </UnorderedList>

            <p>
              The evolution of the logistics pattern is essential to follow the evolution of the cross-border eCommerce pattern. Cost, speed and safety are the prosperity of the industry and the normal state of cross-border logistics.
            </p>

            <Image src={CrossBorderImg} alt="cross-border logistics" />

            <h5>
              <strong>
                Long-distance logistics continues to be under high pressure
              </strong>
            </h5>

            <p>
              The emergence of cross-border eCommerce has greatly shortened the distance between people and goods, and cross-border logistics has also greatly reduced the costs and risks of sellers. Efficient and stable logistics can help sellers reduce risks and reduce costs. 
            </p>

            <p>
              With space for time, local fulfillment seems to be  a classical logistics solution. The penetration rate of overseas eCommerce has increased, the proportion of online retail has reached a new level, and online shopping habits have been solidified. Small packages can no longer meet the market increase and buyer requirements. Using local fulfillment to meet the need for inventory turnover, the new market can effectively reduce inventory slowness.
            </p>

            <p>
              The World Air Transport Association (IATA) predicts that global air transport will experience bankruptcies and layoffs, and it will be difficult to recover in the short term. It is expected that it will not return to 2019 levels until 2024. After the epidemic, buyers are more sensitive to timeliness. Whether sellers use overseas warehouses depends on the cost of capital occupation and buyers' expectations of timeliness. With the development of online shopping habits, buyers' expectations of high-frequency products and sudden demand after the epidemic will be very high.
            </p>

            <h5>
              <strong>
                Change of supply chain service providers 
              </strong>
            </h5>

            <p>
              The fragmented, small-batch, and high-frequency shipping of B2C cross-border eCommerce logistics providers will become mainstream. Under the epidemic and various logistics disasters during the peak season, more and more eCommerce sellers realize that service providers with stable channel resources are reliable partners for stable business, and low prices are no longer a priority.
            </p>

            <p>
              After the epidemic, a one-stop shipping solution, diversified service portfolio, and fast response will become the reference standard for judging an excellent logistics service provider.If you are still not sure which shipping strategy is the best for your eCommerce business, <a href="https://shipkoo.com/">Shipkoo</a> will be here to help and become your reliable shipping and fulfillment partner. 
            </p>

            <Image src={ShipkooImg} alt="shipkoo ship globally" />     

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;